<template>
  <main class="admin">
    <div class="header__logo" :class="{toggled: toggle}">
      <svg class="icon__logo" width="1920px" height="1080px">
        <use xlink:href="svg-symbols.svg#logo"></use>
      </svg>
    </div>
    <div class="container">
      <el-tabs :tab-position="tabPosition" v-show="!autorization.state">
        <el-tab-pane label="Адреса">
          <div style="margin-bottom: 20px;">
            <el-button size="small" @click="addBranch" plain>
              Добавить отделение
            </el-button>
          </div>
          <el-tabs type="border-card" v-model="tabActive" closable @tab-remove="removeBranch">
            <el-tab-pane v-for="(item, index) in branch" :label="item.info.name" :key="item.id" :name="item.id">
              <el-form ref="form" :model="item.info">
                <el-form-item label="Краткий адрес (Будет использоватся в табиках)">
                  <el-input v-model="item.info.name"></el-input>
                </el-form-item>
                <el-form-item label="Полный адрес">
                  <el-input v-model="item.info.address"></el-input>
                </el-form-item>
                <el-form-item label="Как добраться">
                  <el-input v-model="item.info.hint"></el-input>
                </el-form-item>
                <el-form-item label="Часы работы">
                  <el-input v-model="item.info.openingHours"></el-input>
                </el-form-item>
                <el-form-item label="Телефон">
                  <el-input v-model="item.info.phone"></el-input>
                </el-form-item>
                <el-form-item label="Ссылка для записи">
                  <el-input v-model="item.info.link"></el-input>
                </el-form-item>
                <el-form-item label="Фото объекта">
                  <label class="f-file" v-loading="imgLoading.object"
                         element-loading-text="Загрузка..."
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(0, 0, 0, 0.8)">
                    <input type="file" accept=".png,.jpg,.jpeg" class="f-file__input"
                           @change="objectImgUpload($event.target.files, index)"/>
                    <el-image v-if="item.info.objectImg.length > 1" :src="item.info.objectImg">
                      <template #placeholder>
                        <div class="image-slot">
                          Loading<span class="dot">...</span>
                        </div>
                      </template>
                    </el-image>
                    <!--                    <img v-if="item.info.objectImg.length > 1" :src="item.info.objectImg" alt="">-->
                    <span v-else class="f-file__icon"></span>
                  </label>
                </el-form-item>
                <el-form-item label="Мастера">
                  <el-row :gutter="20" class="masters">
                    <el-col :span="8" v-for="(person, index) in item.team">
                      <div class="masters__item">
                        <label class="f-file" v-loading="imgLoading.team === index"
                               element-loading-text="Загрузка..."
                               element-loading-spinner="el-icon-loading"
                               element-loading-background="rgba(0, 0, 0, 0.8)">
                          <input type="file" accept=".png,.jpg,.jpeg" class="f-file__input"
                                 @change="personImgUpload($event.target.files, index, item.id)"/>
                          <img v-if="person.img.length > 1" :src="person.img" alt="">
                          <span v-else class="f-file__icon"></span>
                        </label>
                        <el-form-item label="Имя">
                          <el-input v-model="person.name"></el-input>
                        </el-form-item>
                        <el-form-item label="Ссылка для записи">
                          <el-input v-model="person.link"></el-input>
                        </el-form-item>
                        <el-form-item label="Описание">
                          <el-input type="textarea" rows="4" v-model="person.desc"></el-input>
                        </el-form-item>
                        <i class="el-icon-close" @click="deleteMaster(index, item.id)"></i>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <el-button type="primary" icon="el-icon-plus" plain circle
                                 @click="addMaster(item.id)"></el-button>
                    </el-col>

                  </el-row>
                </el-form-item>
                <el-form-item v-show="changes.state">
                  <el-button type="primary" :loading="changes.loading" @click="saveChanges(branch)">Сохранить
                  </el-button>
                  <el-button @click="uploadBranch()">Отменить изменения</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="Цены">
          <el-form ref="form" :model="prices">
            <el-form-item label="Борода">
              <el-input v-model="prices.beard" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Борода у старшего мастера">
              <el-input v-model="prices.beardUp" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Депиляция">
              <el-input v-model="prices.depilation" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Стрижка">
              <el-input v-model="prices.haircut" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Стрижка у старшего мастера">
              <el-input v-model="prices.haircutUp" type="number"></el-input>
            </el-form-item>
            <el-form-item v-show="priceChanges.state">
              <el-button type="primary" @click="savePrices(prices)">Сохранить</el-button>
              <el-button @click="uploadPrices()">Отменить изменения</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Прочее">
          <h2>Ссылка на salut-school</h2>
          <el-form ref="form" :model="others.schoolLink">
            <el-form-item label="текст">
              <el-input v-model="others.schoolLink.text" placeholder="текст" type="text"></el-input>
            </el-form-item>
            <el-form-item label="ссылка">
              <el-input v-model="others.schoolLink.link" type="text" placeholder="ссылка"></el-input>
            </el-form-item>
            <el-form-item v-show="othersChanges.state">
              <el-button type="primary" @click="saveOthers(others)">Сохранить</el-button>
              <el-button @click="uploadOthers()">Отменить изменения</el-button>
            </el-form-item>
            <h2>Кнопка записаться ( Первый экран )</h2>
            <el-form ref="form" :model="others.firstScreenLink">
              <el-form-item label="ссылка">
                <el-input v-model="others.schoolLink" type="text" placeholder="ссылка"></el-input>
              </el-form-item>
              <el-form-item v-show="othersChanges.state">
                <el-button type="primary" @click="saveOthers(others)">Сохранить</el-button>
                <el-button @click="uploadOthers()">Отменить изменения</el-button>
              </el-form-item>
            </el-form>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </main>
  <el-dialog
      title="Авторизация"
      v-model="autorization.state"
      width="40%"
      destroy-on-close
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :open-delay="1000"
  >
    <el-form :model="autorization">
      <el-form-item label="Логин">
        <el-input
            placeholder=""
            prefix-icon="el-icon-user-solid"
            :class="{failed: autorization.log.failed}"
            v-model="autorization.log.value">
        </el-input>
        <div v-if="autorization.log.failed" style="color: #E7634D;opacity: 0.5">Неверный логин</div>
      </el-form-item>
      <el-form-item label="Пароль">
        <el-input
            placeholder=""
            prefix-icon="el-icon-lock"
            :class="{failed: autorization.pass.failed}"
            show-password
            v-model="autorization.pass.value">
        </el-input>
        <div v-if="autorization.pass.failed" style="color: #E7634D;opacity: 0.5">Неверный пароль</div>
      </el-form-item>
    </el-form>
    <template #footer>
    <span class="dialog-footer">
      <el-button @click="autorizationCheck" :loading="autorization.loading">Войти</el-button>

    </span>
    </template>

  </el-dialog>
</template>

<script>
import {mapActions} from 'vuex'
import firebase from "firebase/app"
import 'firebase/storage';

export default {
  name: "Admin",
  data() {
    return {
      tabPosition: "left",
      tabActive: '0',
      branch: [],
      prices: {},
      others: {
        schoolLink: {
          link: '',
          text: ''
        }
      },
      changes: {
        lock: true,
        state: false,
        loading: false
      },
      priceChanges: {
        lock: true,
        state: false
      },
      othersChanges: {
        lock: true,
        state: false
      },
      imgLoading: {
        object: false,
        team: null
      },
      firstLoadState: true,
      autorization: {
        state: true,
        log: {
          value: '',
          failed: false
        },
        pass: {
          value: '',
          failed: false
        },
        loading: false
      }
    };
  },
  computed: {
    state() {
      return this.$store.state
    }
  },
  methods: {
    ...mapActions(['saveBranch', 'loadAppData', 'deleteBranch', 'savePrices', 'saveOthers']),
    uploadBranch() {
      let data = this.state.branch
      this.branch = JSON.parse(JSON.stringify(data))
      setTimeout(() => {
        this.changes.lock = false
        this.changes.state = false
        this.tabActive = this.branch[0].id
      }, 0)
    },
    saveChanges(branch) {
      this.changes.loading = true
      this.saveBranch(branch).then(() => {
        this.changes.loading = false
        this.changes.state = false

      })
    },
    uploadPrices() {
      let prices = this.state.prices;
      this.prices = JSON.parse(JSON.stringify(prices))
      setTimeout(() => {
        this.priceChanges.lock = false
        this.priceChanges.state = false
      }, 0)
    },
    uploadOthers() {
      let others = this.state.others;
      this.others = JSON.parse(JSON.stringify(others))
      setTimeout(() => {
        this.othersChanges.lock = false
        this.othersChanges.state = false
      }, 0)
    },
    objectImgUpload(file, branchIndex) {
      if (!file.length || !file[0].type.match('image')) {
        return;
      }
      this.imgLoading.object = true

      const storageRef = firebase.storage().ref().child(`branch/${file[0].name}`);
      storageRef.put(file[0]).then((snapshot) => {
        storageRef.getDownloadURL()
            .then((url) => {
              const xhr = new XMLHttpRequest();
              xhr.responseType = 'blob';
              xhr.onload = (event) => {
                const blob = xhr.response;
              };
              xhr.open('GET', url);
              xhr.send();
              this.branch[branchIndex].info.objectImg = url
              this.imgLoading.object = false

            })
            .catch((error) => {
              // Handle any errors
            });
      });
    },
    personImgUpload(file, personIndex, branchId) {
      if (!file.length || !file[0].type.match('image')) {
        return;
      }
      this.imgLoading.team = personIndex

      const branchIndex = this.branch.findIndex(el => el.id === branchId)
      const storageRef = firebase.storage().ref().child(`team/${file[0].name}`);
      storageRef.put(file[0]).then((snapshot) => {
        storageRef.getDownloadURL()
            .then((url) => {
              const xhr = new XMLHttpRequest();
              xhr.responseType = 'blob';
              xhr.onload = (event) => {
                const blob = xhr.response;
              };
              xhr.open('GET', url);
              xhr.send();
              this.branch[branchIndex].team[personIndex].img = url
              this.imgLoading.team = null

            })
            .catch((error) => {
              // Handle any errors
            });
      });
    },
    addMaster(branchId) {
      const branchIndex = this.branch.findIndex(el => el.id === branchId)
      this.branch[branchIndex].team.push({
        img: '',
        desc: '',
        link: '',
        name: ''
      })

    },
    addBranch() {
      this.branch.push({
        id: '',
        info: {
          address: '',
          phone: '',
          hint: '',
          objectImg: '',
          openingHours: '',
          name: 'Новое отделение',
          link: ''
        },
        team: [
          {
            img: '',
            desc: '',
            link: '',
            name: ''
          }
        ]
      })
    },
    deleteMaster(masterId, branchId) {
      const branchIndex = this.branch.findIndex(el => el.id === branchId)
      this.branch[branchIndex].team.splice(masterId, 1);
    },
    removeBranch(branchId) {
      this.$confirm('Отдел не подлежит восстановлению, продолжить?', 'Warning', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Отменить',
        type: 'warning'
      }).then(() => {
        const branchIndex = this.branch.findIndex(el => el.id === branchId)
        this.branch.splice(branchIndex, 1);
        this.$message({
          type: 'success',
          message: 'Отдел удалён'
        });
        this.deleteBranch(branchId)
      })
    },
    autorizationCheck() {
      this.autorization.loading = true
      firebase.firestore().collection("general").doc('auth').get().then(doc => {
        const data = doc.data()
        this.autorization.log.failed = this.autorization.log.value !== data.log
        this.autorization.pass.failed = this.autorization.pass.value !== data.pass
        this.autorization.state = !(!this.autorization.log.failed && !this.autorization.pass.failed)
        this.autorization.loading = false
      })

    },

  },
  watch: {
    branch: {
      deep: true,
      handler() {
        if (!this.changes.lock) this.changes.state = true
      }
    },
    prices: {
      deep: true,
      handler() {
        if (!this.priceChanges.lock) this.priceChanges.state = true
      }
    },
    others: {
      deep: true,
      handler() {
        if (!this.othersChanges.lock) this.othersChanges.state = true
      }
    },
  },
  created() {
    if (this.firstLoadState) {
      this.loadAppData().then(() => {
        this.uploadBranch()
        this.uploadPrices()
        this.uploadOthers()

      })
    } else {
      this.uploadBranch()
      this.uploadPrices()
      this.uploadOthers()

    }
  },
  mounted() {
    this.firstLoading = false
  }
}
</script>

<style lang="scss">
.el-dialog__body .el-form-item {
  width: 70%;
  margin: 0 auto;

  .el-input {
    max-width: initial;
  }

  .el-form-item__label {
    display: flex;
    justify-content: center;
  }
}

.el-input.failed .el-input__inner {
  border-color: #E7634D;
}

.admin {
  min-height: 100vh;
  color: #fff;
  padding-top: 100px;
}

.el-form-item {
  flex-direction: column;
  justify-content: flex-start;
}

.el-form-item__label {
  text-align: left !important;

}

.branch__add {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
}

.f-file {
  $f-file: &;
  display: inline-flex;
  cursor: pointer;
  width: 220px;
  height: 220px;
  position: relative;
  border: 1px dashed #333;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.1s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    cursor: pointer;
  }

  &__icon {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #333;
    right: 50%;
    top: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    transition: all 0.2s ease-in-out;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 0H9V9H0V12H9V21H12V12H21V9H12V0Z' fill='white'/%3E%3C/svg%3E%0A");
    transform: translate(50%, -50%);
  }

  &__icon + &__label {
    margin-left: 16px;
  }

  &.active &__icon {
    transform: translate(50%, -50%) rotate(45deg);
  }
}

.el-card__body {
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}

.branch__delete {
  position: absolute;
  top: 0;
  right: 0;
}

.masters__item {
  border: 1px solid #C0C4CC;
  padding: 16px;
  position: relative;
  padding-top: 46px;

  .f-file {
    width: 100%;
  }

  .el-input {
    max-width: 100%;
  }

  .el-icon-close {
    color: #333;
    font-size: 26px;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.masters {
  & > *:last-child {
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-height: 250px !important;
  }
}

.el-tabs > .el-tabs__header .el-tabs__item .el-icon-close {
  position: relative;
  font-size: 12px;
  width: 0;
  height: 14px;
  vertical-align: middle;
  line-height: 15px;
  overflow: hidden;
  top: -1px;
  right: -2px;
  transform-origin: 100% 50%;
}

.el-tabs > .el-tabs__header .el-tabs__item.is-closable:hover .el-icon-close {
  width: 14px;
}

.el-input {
  max-width: 50%;
}

.el-tabs > .el-tabs__header .el-tabs__item.is-closable:hover {
  padding-left: 13px;
  padding-right: 13px;
}

.el-tabs--left .el-tabs__item.is-left {
  color: #e9ecef;
}

.el-tabs--left .el-tabs__item.is-active {
  color: #409EFF;
}

.el-tabs--left .el-tabs__header.is-left {
  margin-right: 65px;
}
.admin .header__logo {
  z-index: -1;
}
.el-form-item__label {
  text-align: left !important;
}
</style>
